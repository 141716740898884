<!-- 病房智能交互系统 -->
<template>
  <div class="wrapper pageBox product-wrapper">
    <Banner title="互联网+护理上门" describe="护理上门平台为以老年群体、院后康复群体、母婴群体为主的特殊群体提供智能化、专业化的居家护理、医学康复、母婴护理等护理延伸服务，精准对接人民群众多样化、多层次的健康需求。平台建设依托互联网等信息技术，利用在平台医疗机构下注册的护士，以“线上申请、线下服务”的模式，将护理服务从医疗机构延伸至家庭、社区，打通居家医养结合“最后一公里”。" />
    <div class="product-content">
      <div class="product-name">产品亮点</div>
      <div class="introduction-content">
        <img src="@/assets/product/netNursing1.png" class="img1" alt="">
      </div>
    </div>
  </div>
</template>

<script>

import Banner from '@/components/Banner';
export default {
  name: '',
  components: {
    Banner
  },
  data() {
    return {
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {

  }
};
</script>

<style lang='scss' scoped>
.product-wrapper {
  .product-content {
    background: #fff;
    padding-bottom: 240px;
  }
  .introduction-content {
    width: 1332px;
    margin: 0 auto;
    .img1 {
      width: 100%;
      height: 555px;
    }
  }
}
</style>
